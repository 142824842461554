<template>
  <div>
    <el-select
      ref="select"
      popper-class="search-select"
      :key="key"
      v-show="display"
      v-model="compValue"
      filterable
      remote
      reserve-keyword
      clearable
      clear-fix
      :placeholder="placeholder"
      :collapse-tags="compValue && compValue.length > 5 && collapseTags"
      :allow-create="allowCreate"
      :multiple="multiple"
      :size="size"
      :disabled="disabled"
      :remote-method="remoteMethod"
      :loading="loading"
      @change="onChange"
      @clear="clearResultSearch"
      @remove-tag="clearTagSearch">
      <div v-if="this.multiple" class="pl-3">
        <el-button type="text" size="small" @click="selectAllValues">Выбрать всё</el-button>
      </div>
      <el-option
        v-for="(item) in options"
        v-show="!item.hidden"
        :key="item[optionsKeyProp]"
        :label="item[label]"
        :value="item[returnProp]">
      </el-option>
    </el-select>
    <el-select :value="initialValue" :disabled="disabled" :multiple="multiple" v-show="!display" :size="size" clear-fix />
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SearchInput',
  props: {
    value: { type: [Array, String, Number], default: () => '' },
    initialValue: { type: [Array, String], default: () => null },
    optionsKeyProp: { type: String, default: () => 'id' },
    
    searchUrl: { type: String, default: () => '' },
    searchList: { type: Array, default: () => [] },
    
    label: { type: String, default: () => 'value' },
    startLength: { type: Number, default: () => 1 },
    disabled: { type: Boolean, default: () => false },
    size: { type: String, default: () => 'small' },
    returnProp: { type: String, default: () => 'value' },
    multiple: { type: Boolean, default: () => false },
    allowCreate: { type: Boolean, default: () => true },
    showWhenInit: { type: Boolean, default: () => false },
    collapseTags: { type: Boolean, default: () => false },
    placeholder: { type: String, default: () => 'Поиск...' },
  },
  async created() {
    if (this.searchList && this.searchList.length > 0) {
      if (this.startLength === 0) {
        this.options = this.searchList;
      } else {
        this.options = this.searchList.filter((x) => this.value.includes(x[this.optionsKeyProp]));
      }
    } else {
      this.options = [];
    }
    await this.setInitialValue();
  },
  data() {
    return {
      initialKey: this.value,
      loading: false,
      display: true,
      key: 0,
    };
  },
  computed: {
    compValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch: {
    async searchList() {
      if (this.startLength === 1) {
        this.startLength = (this.searchList && this.searchList.length > 200 ? 3 : 0);
      }
      if (this.startLength === 0) {
        this.options = this.searchList;
        this.key += 1;
      } else {
        this.options = this.searchList.filter((x) => this.value.includes(x[this.optionsKeyProp]));
        this.key += 1;
      }
    },
    async initialValue(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        await this.setInitialValue();
      }
    },
    async value(newVal) {
      // console.log('new value', newVal);
      if (this.options.length === 0) {
        this.options = this.searchList.filter((x) => newVal.includes(x[this.optionsKeyProp]));
      }
    }
  },
  methods: {
    onChange() {
      this.$nextTick(() => {
        // this.options = this.options.concat(newVal);
        // console.log(this.$refs.select);

        // нужно в редких случаях (см орг причины в карточке ф-ий
        this.$emit('label-update', this.$refs.select.selectedLabel);
        this.$refs.select.query = '';
      });
    },
    selectAllValues() {
      const allValues = [];
      // eslint-disable-next-line no-restricted-syntax
          for (const item of this.options) {
            allValues.push(item[this.returnProp]);
          }        
        this.compValue = allValues;
        this.$emit('label-update', this.$refs.select.selectedLabel);
        this.$refs.select.query = '';
    },
    async remoteMethod(searchString) {
      if (searchString.length >= this.startLength) {
        this.loading = true;
        if (this.searchUrl.length > 0) {
          const res = await axios.get(this.searchUrl, { params: { searchField: this.searchField, searchString } });
          if (res && res.data) {
            // если были предустановленные значения, их надо оставить и спрятать чтоб они не пропадали...
            /* if (this.initialValue && this.multiple) {
              this.options = this.initialValue.map((t) => ({ ...t, hidden: true })).concat(res.data);
            } else {
              this.options = res.data;
            } */

            // По крайней мере для одного значения теперь есть
            this.options = res.data;
            if (this.initialValue && !this.multiple) {
              this.addInitialValue(this.options);
            }
            this.loading = false;
          }
        } else {
          const res = this.searchList.filter((element) => !searchString || element[this.label].toLowerCase().includes(searchString.toLowerCase()) || this.compValue.includes(element[this.optionsKeyProp]));
          this.options = res;
          if (this.initialValue && !this.multiple) {
            this.addInitialValue(this.searchList);
          }
          this.loading = false;
        }
      } else {
        this.options = [];
      }
    },
    async setInitialValue() {
      if (this.showWhenInit && (this.initialValue)) this.display = false;
      if (this.initialValue) {
        if (this.searchUrl.length > 0) {
          if (!this.multiple) {
            const res = await axios.get(this.searchUrl, {
              params: { searchField: this.searchField, searchString: this.initialValue }
            });
            if (res && res.data) this.options = res.data;
            this.addInitialValue(this.options);
          } else {
            this.options = this.initialValue;
          }
        } else {
          this.addInitialValue(this.searchList);
        }
        this.display = true;
        this.key += 1;
      }
    },
    addInitialValue(options) {
      let found = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const i in options) {
        if (options[i].id === this.initialKey) {
          found = true;
          break;
        }
      }
      if (!found) {
        /* let toPush;
        toPush[this.optionsKeyProp] = this.initialKey;
        toPush[this.label] = this.initialValue;
        options.push(toPush); */
        options.push({ id: this.initialKey, value: this.initialValue });
        // console.log('push');
      }
    },
    clearResultSearch() {
      this.options = [];
    },
    clearTagSearch(item) {
      const filtered = this.options.filter((x) => x !== item);
      this.options = filtered;
    }
  }
};
</script>

<style scoped>

</style>
